.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  background-color: transparent !important;
  color: $cb-white !important;
  border: none;
  margin: 0;
}

.nav-tabs .nav-link.active {
  background-color: $cb-red !important;
  color: $cb-white !important;
  border: none;
}

.nav-tabs .nav-link.active.footer-bg{
  background-color: transparent !important; 
  color: white !important; 
}

.with-breadcrumbs {
  transform: translateY(-20px);
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
  font-size: 80%;

  .crumb,
  .assortment-button {
    padding: 6px;
    cursor: pointer;
    border-radius: 8px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;

    &::before {
      content: ">";
      position: relative;
      margin-right: 8px;
      font-size: 85%;
      transform: scale(1,2);
      display: inline-block;
    }

    &.assortment-button {
      color: black;
      background: white;

      &::before {
        content: "<";
      }
    }
  }
}

@include media-breakpoint-up(xs) {
  .breadcrumbs {
    font-size: 80%;
  }
}
@include media-breakpoint-up(sm) {
  .breadcrumbs {
    font-size: 90%;
  }
}
@include media-breakpoint-up(md) {
  .breadcrumbs {
    font-size: 100%;
  }
}
@include media-breakpoint-up(lg) {
  .breadcrumbs {
    font-size: 110%;
  }
}
@include media-breakpoint-up(xl) {
  .breadcrumbs {
    font-size: 120%;
  }
}