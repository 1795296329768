.spinner-wrapper {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-rel {
  position: relative;
}

.spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;

  background: linear-gradient(300deg, #595858, #838181);
  background-size: 120% 120%;
  animation: gradient-animation 2s ease infinite;

  opacity: .1;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

$minHeights:
        50,
        100,
        150,
        200,
        250,
        300,
        350,
        400,
        450,
        500;

@each $height in $minHeights {
  .mh-#{$height} {
    min-height: #{$height}px;
  }
}