.text-field {
  width: 100%;
  display: block;
  margin-bottom: 12px;

  input {
    width: 100%;
    border-radius: 4px;
    border: unset;
    padding: 9px 12px;
    background: #252523;
    color: $cb-white;

    color-scheme: dark;
  }
}