.account-page .order-link {
  background: #252523;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .order-button {
    @extend .btn, .btn-primary;

    padding-left: 5.25px;
    padding-right: 5.25px;
    margin-left: 15px;
  }
}

.account-page .order-col {
  background: #252523;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;

  .order-button {
    @extend .btn, .btn-primary;

    padding-left: 5.25px;
    padding-right: 5.25px;
    margin-left: 15px;
  }
}