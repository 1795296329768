.price-request-modal .modal-content {
  background: $cb-red;

  .price-request-modal-header {
    border-bottom: unset;
  }
  .price-request-modal-body {

  }
  .price-request-modal-footer {
    border-top: unset;

    .btn {
      background: white;
      border: unset;
      color: $cb-red;
    }
  }
}