.accountMenu{
    .product-categories{
        padding-left: 14px;
        padding-top: 14px;
        padding-bottom: 14px;

        .account-menu-links {
            text-decoration: none;
            color: inherit;
            display: block;
            line-height: 3rem;
            cursor: pointer;

            &.active {
                border-right: 5px solid white; 
            }
            .accountMenuIcons{
                margin-right: 0.5rem;
                font-size: 1.5rem;
            }
        }
    }
}