// GENERAL PRODUCT STYLING

.product-price-badge {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-y: hidden;
  overflow-x: auto;
  user-select: none;
  padding-bottom: 15px;
  color-scheme: dark;

  .badge {
    padding: 7px !important;
    font-size: 1rem;
    font-weight: 500;

    small {
      margin-left: 5px;
      font-size: 10px;
    }
  }
}

.product-image {
  margin-right: 10px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}

.instock {
  color: green;
}

.product-page .quantity-input-group {
  max-width: 160px;
}

.add-to-cart {
  gap: 10px;
}
.gap-10 {
  gap: 10px;
}

.add-to-cart .input-group {
  width: auto;
}
.cart-qty.form-control {
  border: none;
  text-align: center;
  color: $cb-white
}

.product-tabs {
  .nav-tabs {
    background-color: #262626;
    border-radius: 5px 5px 0 0;
  }

  .nav-tabs .nav-link.active {
    background-color: #2e2e2e !important;
  }

  .tab-content {
    background-color: #2e2e2e;
    padding: 25px;
    border-radius: 0 0 5px 5px;
  }
}

.product-card {
  color: $cb-white;
  --bs-card-bg: #262626;

  a {
    color: $cb-white;
    text-decoration: none;
  }

  .product-price-badge {
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }

  h5 {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .card-body {
    min-height: 170px;
  }
}

.flex-grow {
  flex-grow: 1;
}

// PRODUCT PAGE STYLING

.product-page {
  .product-price-badge .badge {
    padding: 10px !important;
    font-size: 24px;
    font-weight: 500;
  }
}