.pagination {
  display: flex;
  justify-content: center;

  .pagination-links {
    display: flex;

    .pagination-link {
      color: white;
      font-size: 18px;
      text-decoration: none;

      &.active {
        color: $cb-red;
      }
    }
  }
}

select.items-per-page, select.delivery-address {
  background-color: $cb-gray-dark;
  background-image: url("../../root/page/commons/images/select-icon.svg");
  color: $cb-white;

  &:focus {
    border-color: $cb-red;
    box-shadow: 0 0 0 0.25rem $cb-red-25;
  }
}