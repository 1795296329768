$sizeMap: (
        "image": 20%,
        "desc": 33%,
        "qty": 20%,
        "price": 14%,
        "total": 13%,
);

.h-150 {
  height: 150px;
}

.cart, .cart-totals {
  background-color: #2e2e2e;
  border-radius: 5px;
  padding: 10px;

  .cart-price {
    color: $cb-red;
    font-weight: 500;
  }

  .cart-table-header {
    font-weight: 500;
    border-bottom: 2px solid #3b3b3b;

    th {
      padding: 15px 0;
    }

    th.cart-th-qty {
      min-width: 135px;
      padding-left: 10.5px
    }

    @each $name, $size in $sizeMap {
      .cart-th-#{$name} {
        width: $size;
      }
    }
  }

  .remove-from-cart {
    color: white;
    font-size: 16px;
    font-weight: 300;
    text-decoration: none;
  }

  img {
    border-radius: 5px;
    margin-right: 4px;
  }

  .cart-table-row {
    min-height: 125px;
    border-bottom: 2px solid $cb-gray-light;

    td {
      min-height: 125px;
      vertical-align: top;
      padding: 12px 6px;
    }

    td.qty {
      padding-left: 0;
    }

    .quantity-input-group {
      button, input {
        padding-top: 0;
      }
    }
  }

  div[role='alert'] span {
    font-size: 13px;
    font-weight: 200;
  }

}
