html {
  font-size: 14px;
  background-color: $cb-gray-dark;
  height: 100%;
}

body {
  min-height: 100%;
  background-image: url("../../root/page/commons/images/background-shop.png");
  color: $cb-white;
  font-weight: 300;

  &.not-found {
    background:
    linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url('../../root/page/commons/images/cornyBakerBg.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top 10px right 50%;
  }
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

.link {
  cursor: pointer;
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
                  45deg,
                  var(--brand-primary),
                  var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla-thumbs {
  --thumbs-slide-spacing: 0.8rem;
  --thumbs-slide-height: 9rem;
  margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport {
  overflow: hidden;
}
.embla-thumbs__container {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
}
.embla-thumbs__slide {
  flex: 0 0 28%;
  min-width: 0;
  padding-left: var(--thumbs-slide-spacing);
  position: relative;
}
@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 25%;
  }
}
.embla-thumbs__slide__button {
  -webkit-appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: block;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__button {

}
.embla-thumbs__slide__img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.embla-thumbs__slide__number {
  width: 3rem;
  height: 3rem;
  z-index: 1;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 3rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla-thumbs__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
                  45deg,
                  var(--brand-primary),
                  var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.4rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

div[role="alert"] {
  height: max-content;
}

.cursor-pointer {
  cursor: pointer;
}