.btn-cb-red {
  background-color: $cb-red;
  border-color: $cb-red;
  color: white;

  &:hover {
    background-color: $cb-red-hover;
    border-color: $cb-red-hover;
  }
}

.btn-link {
  color: white;
  text-decoration: none;
  font-weight: 300;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.btn-favorite, .btn-cart {
  padding: 5px;
}

.btn-dark {
  &:hover {
    background-color: $cb-gray-lightest;
    border-color: $cb-gray-lightest;
  }
}