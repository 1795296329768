.product-categories {
  color: $cb-white;

  .accordion-header button {
    background-color: $cb-red;
    color: $cb-white;
    border-radius: 4.25px;
    background-image: url("../../root/page/commons/images/select-icon.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 16px;

    &:after {
      content: none;
    }
    &:focus {
      border-bottom: 1px solid $cb-white;
      box-shadow: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-weight: 300;

    a {
      color: $cb-white;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;

      li {
        padding: 14px 17.5px;

      }
    }
  }
}

.product-range {
  .view-type {
    margin-left: 25px;
    padding: 5px;

    svg {
      transform: scale(2);
    }
  }
}
