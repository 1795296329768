.relation-data {
  background: #252523;
  padding: 20px;
  border-radius: 4px;
}

.cart-totals tr hr {
  margin: unset;
  opacity: .1;
}

.cart-totals .cart-table-row h6 {
  line-height: 21px;
}

.checkout-completed {

  .checkout-completed-notification {
    background: #252523;
    padding: 20px;
    border-radius: 4px;

    h2 {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 20px;
    }

    a {
      color: $cb-red;
    }
  }

  .checkout-completed-overview {
    background: #252523;
    padding: 20px;
    border-radius: 4px;

    thead th {
      padding: 0 0 15px 0;
    }

    tbody td {
      padding: 15px 4px;
      vertical-align: top;
    }

    tbody tr {
      border-bottom: 2px solid #3b3b3b;
    }

    tfoot td {
      padding: 10px 0;
    }

    tfoot tr:last-of-type {
      border-top: 2px solid #3b3b3b;

      td {
        font-weight: 700;
      }
    }
  }
}