body {
  margin: 0;
}

.login-page-wrapper {
  background: url('../../root/login/modules/commons/images/background-login.jpg') no-repeat center center;
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.4);
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    padding-left: 0;
    padding-right: 0;
    color: $cb-white;

    .login-header {
      display: flex;
      justify-content: center;
      margin-bottom: -35px;

      img {
        width: 150px;
      }
    }

    .login-card {
      background-color: $cb-gray-light;
      border-radius: 5px;

      h4 {
        font-weight: 500;
      }

      input {
        background-color: $cb-gray-lighter;
        color: white;
        border-color: $cb-gray-lighter;

        &::placeholder {
          color: #8d8d8d;
          font-size: smaller;
        }

        &:focus {
          background-color: $cb-gray-lighter;
          color: white;
          border-color: $cb-red;
          box-shadow: 0 0 0 0.25rem $cb-red-25;
        }
      }

      input[type=checkbox] {

        &:checked {
          background-color: $cb-red;
          border-color: $cb-red;
          box-shadow: 0 0 0 0.25rem $cb-red-25;
        }
      }
    }

    a {
      color: $cb-white;
      text-decoration: none;
    }
  }
}
