.icon {
  &-primary {
    color: $primary;

    &:hover {
      color: lighten($primary, 10%);
      cursor: pointer;
    }
  }

    &-secondary {
        color: $secondary;

        &:hover {
        color: lighten($secondary, 10%);
        cursor: pointer;
        }
    }

    &-success {
        color: $success;

        &:hover {
        color: lighten($success, 10%);
        cursor: pointer;
        }
    }

    &-info {
        color: $info;

        &:hover {
        color: lighten($info, 10%);
        cursor: pointer;
        }
    }

    &-warning {
        color: $warning;

        &:hover {
        color: lighten($warning, 10%);
        cursor: pointer;
        }
    }

    &-danger {
        color: $danger;

        &:hover {
        color: lighten($danger, 10%);
        cursor: pointer;
        }
    }

    &-light {
        color: $white;

        &:hover {
        color: darken($white, 10%);
        cursor: pointer;
        }
    }

    &-dark {
        color: $black;

        &:hover {
        color: lighten($black, 30%);
        cursor: pointer;
        }
    }

  &-orange {
    color: $orange;

    &:hover {
      color: lighten($orange, 10%);
      cursor: pointer;
    }
  }
}