.w-30 {
  width: 30% !important;
}

// Overwrite some of the default bootstrap css.
$font-family-sans-serif: Montserrat, sans-serif;
$alert-padding-y: 0.8rem;

// Add some custom Corny Bakers variables.
// Colors.
$cb-gray-dark: #191918;
$cb-gray-lighter: #1e1e1e;
$cb-gray-light: #2d2d2d;
$cb-gray-lightest: #343434;
$cb-red: #B11E17;
$cb-red-50: #B11E1750;
$cb-red-25: #B11E1725;
$cb-red-hover: #80130f;
$cb-green: #1c6539;
$cb-beige: #a47b49;
$cb-blue: #397db4;
$cb-white: #fff;

$primary: $cb-red;
$secondary: $cb-gray-light;
$success: $cb-green;
$info: $cb-blue;
$warning: $cb-beige;
$danger: $cb-red;
$light: $cb-gray-lightest;
$dark: $cb-gray-dark;
$orange: #F66E22;

$theme-colors: (
        "primary":    $cb-red,
        "secondary":  $cb-gray-light,
        "success":    $cb-green,
        "info":       $cb-blue,
        "warning":    $cb-beige,
        "danger":     $cb-red,
        "light":      $cb-gray-lightest,
        "dark":       $cb-gray-dark
);

$container-max-widths: (
        sm: 749px, // This gets rid of a lot of the padding on mobile devices.
        md: 750px,
        lg: 1000px,
        xl: 1200px,
        xxl: 1320px
) !default;