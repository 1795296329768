.not-found-page{
    height: 600px;
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
    }

    p{
        font-size: 25px;
    }

    .headBack404{
        padding-bottom: 20px;
    }

    .title404{
        font-size: 100px; 
    }

    .button {
        background-color: transparent;
        border: 1px solid $cb-white;
        border-radius: 8px;
        box-sizing: border-box;
        color: $cb-white;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        outline: none;
        padding: 13px 23px;
        position: relative;
        text-align: center;
        text-decoration: none;
        touch-action: manipulation;
        transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
        user-select: none;
        -webkit-user-select: none;
        width: auto;
      }

      .button:hover{
        background-color: $cb-red;
        color: $cb-white;
        border-color: transparent;
      }

      .button:active {
        background-color: $cb-red;
        color: $cb-white;
        transform: scale(.96);
      }

      @media screen and (max-width: 990px) {
        p {
          font-size: 20px;
        }

        .title404{
            font-size: 75px;
        }
      }

      @media screen and (max-width: 770px) {
        p {
          font-size: 15px;
        }

        .title404{
            font-size: 60px;
        }
      }

      @media screen and (max-width: 600px) {
        p {
          font-size: 14px;
        }

        .title404{
            font-size: 55px;
        }
      }
      
      @media screen and (max-width: 530px) {
        p {
          font-size: 11px;
        }

        .title404{
            font-size: 50px;
        }
      }
    
      @media screen and (max-width: 400px) {
        p {
          font-size: 10px;
        }

        .title404{
            font-size: 45px;
        }
      }

      @media only screen and (max-width: 350px) and (min-width: 0px) {
        p {
          font-size: 9px;
        }

        .title404{
            font-size: 40px;
        }
      }
}