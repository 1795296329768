.account-page.account {
  .acct-block {
    background: #2e2e2e;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;

    h4 {
      font-size: 110%;
      margin-bottom: 20px;
    }

    input {
      background: #252523;
      color: $cb-white;
      color-scheme: dark;
    }

    .placeholder {
      background: unset;
      opacity: unset;
    }
  }
}