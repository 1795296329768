@import "@fontsource/montserrat";
@import "@fontsource/montserrat/100.css";
@import "@fontsource/montserrat/100-italic.css";
@import "@fontsource/montserrat/200.css";
@import "@fontsource/montserrat/200-italic.css";
@import "@fontsource/montserrat/300.css";
@import "@fontsource/montserrat/300-italic.css";
@import "@fontsource/montserrat/400.css";
@import "@fontsource/montserrat/400-italic.css";
@import "@fontsource/montserrat/500.css";
@import "@fontsource/montserrat/500-italic.css";
@import "@fontsource/montserrat/600.css";
@import "@fontsource/montserrat/600-italic.css";
@import "@fontsource/montserrat/700.css";
@import "@fontsource/montserrat/700-italic.css";
@import "@fontsource/montserrat/800.css";
@import "@fontsource/montserrat/800-italic.css";
@import "@fontsource/montserrat/900.css";
@import "@fontsource/montserrat/900-italic.css";
@import "flag-icons/css/flag-icons.min.css";

@import "custom-bootstrapp";
@import '~bootstrap/scss/bootstrap';
@import 'react-toastify/dist/ReactToastify.css';
@import "default/default";
@import "default/loader";
@import "default/buttons";
@import "default/inputs/inputs";
@import "default/pagination";
@import "header/header";
@import "header/navigation";
@import "header/logo";
@import "login/loginPage";
@import "product/categories";
@import "product/product";
@import "product/price-request-modal";
@import "shoppingcart/cart";
@import "menu/menu";
@import "checkout/checkout";
@import "menu/accountMenu.scss";
@import "account/account.scss";
@import "404/404.scss";
@import "shared";
