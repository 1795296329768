.corny-bakers-modal {
  &-primary {
    .modal-content {
      background: $primary;
    }
  }

  &-secondary {
    .modal-content {
      background: $secondary;
    }
  }

    &-success {
        .modal-content {
        background: $success;
        }
    }

    &-info {
        .modal-content {
        background: $info;
        }
    }

    &-warning {
        .modal-content {
        background: $warning;
        }
    }

    &-danger {
        .modal-content {
        background: $danger;
        }
    }

    &-light {
        .modal-content {
        background: $light;
        }
    }

    &-dark {
        .modal-content {
        background: $dark;
        }
    }

  &-header {
    border-bottom: unset;

    .btn-close {
      --bs-btn-close-bg: url("data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox=%270%200%2016%2016%27%20fill=%27%23fff%27%3e%3cpath%20d=%27M.293.293a1%201%200%200%201%201.414%200L8%206.586%2014.293.293a1%201%200%201%201%201.414%201.414L9.414%208l6.293%206.293a1%201%200%200%201-1.414%201.414L8%209.414l-6.293%206.293a1%201%200%200%201-1.414-1.414L6.586%208%20.293%201.707a1%201%200%200%201%200-1.414z%27/%3e%3c/svg%3e");
    }
  }
  &-body {

  }

  &-footer {
    border-top: unset;

    .btn {
      background: white;
      border: unset;
      color: $cb-red;
    }
  }
}