input[type=checkbox] {
  background-color: $cb-gray-lighter;
  color: $cb-white;
  border-color: $cb-gray-lighter;

  &::placeholder {
    color: #8d8d8d;
    font-size: smaller;
  }

  &:focus {
    background-color: $cb-gray-lighter;
    color: $cb-white;
    border-color: $cb-red;
    box-shadow: 0 0 0 0.25rem $cb-red-25;
  }

  &:checked {
    background-color: $cb-red;
    border-color: $cb-red;
    box-shadow: 0 0 0 0.25rem $cb-red-25;
  }
}
