div.date {
  display: block;
  width: 100%;
  margin-bottom: 12px;

  .react-calendar__month-view__weekdays {
    filter: invert(1);
  }

  .react-calendar--doubleView {
    width: 600px;
  }

  .react-calendar {
    filter: invert(1);
    border: unset;
    border-radius: 4px;
  }
  .react-date-picker__calendar {
    inset: unset !important;
    width: auto;
  }

  .react-date-picker {
    margin-top: 4px;
    display: block;
    width: 100%;

    .react-date-picker__wrapper {
      border-radius: 4px;
      border: unset;
      padding: 9px 12px;
      background: #252523;
      color: $cb-white;

      color-scheme: dark;

      button {
        svg {
          stroke: $cb-white;
        }

        &:hover svg, &:focus svg {
          stroke: $cb-red;
        }
      }
    }
  }
}