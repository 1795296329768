.header {
  background-color: black;

  .header-account {
    gap: 20px;
  }

  .header-icon-wrapper {
    position: relative;
    width: 30px;
  }

  .header-icon {
    font-size: 24px;
  }

  a {
    color: $cb-white;
    text-decoration: none;
  }

  a:hover {
    color: $cb-white;
    text-decoration: none;
    font-weight: 500;
  }

  .cart-qty {
    position: absolute;
    top: -10px;
    right: -10px
  }

  .search::placeholder {
    color: $body-tertiary-color;
  }

  .nav-item a:hover {
    font-weight: 300;
  }

  .custom-dropdown {

    .dropdown-menu {
      background-color: $cb-red;
      min-width: 200px;
      line-height: 2rem;
    }

    .dropdown-item {
      font-weight: 300;

      &:hover {
        background-color: $cb-red-hover;
      }
    }

    .accountMenuIcons {
      margin-right: 0.7rem;
      font-size: 1.22rem;
    }

    .dropdown-toggle::after {
      content: none;
    }

    .dropdown-icon {
      font-size: 20px;
      line-height: 19px;
    }

  }

  .custom-dropdown.lang-select {
    margin-left: 15px;

    .dropdown-menu {
      width: unset;
    }
  }

}
